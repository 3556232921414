export const adjectives = [
  "timely",
  "wet",
  "homing",
  "gamey",
  "size",
  "fat",
  "key",
  "light",
  "top",
  "level",
  "bossy",
  "fun",
  "sound",
  "kind",
  "future",
  "action",
  "bad",
  "class",
  "period",
  "stocky",
  "chance",
  "head",
  "salty",
  "inside",
  "medium",
  "black",
  "bottom",
  "choice",
  "career",
  "north",
  "square",
  "self",
  "shot",
  "front",
  "living",
  "basic",
  "animal",
  "budget",
  "ground",
  "plenty",
  "baby",
  "glass",
  "joint",
  "pack",
  "muscle",
  "red",
  "master",
  "gear",
  "ideal",
  "mother",
  "signal",
  "street",
  "south",
  "novel",
  "sea",
  "blue",
  "dreamy",
  "hourly",
  "team",
  "tricky",
  "blank",
  "creamy",
  "gold",
  "kidding",
  "agent",
  "corner",
  "east",
  "winter",
  "dress",
  "party",
  "proof",
  "west",
  "cross",
  "draft",
  "expert",
  "native",
  "closet",
  "peak",
  "blind",
  "purple",
  "bitter",
  "campy",
  "crack",
  "jury",
  "prized",
  "one",
  "many",
  "most",
  "other",
  "good",
  "great",
  "few",
  "still",
  "public",
  "horror",
  "minute",
  "silver",
  "high",
  "common",
  "set",
  "simple",
  "past",
  "big",
  "major",
  "main",
  "whole",
  "cold",
  "low",
  "worth",
  "green",
  "glad",
  "single",
  "due",
  "middle",
  "active",
  "safe",
  "visual",
  "human",
  "junior",
  "unique",
  "final",
  "broad",
  "rich",
  "young",
  "heavy",
  "normal",
  "white",
  "secret",
  "brown",
  "tough",
  "deep",
  "formal",
  "jump",
  "remote",
  "total",
  "vast",
  "beaten",
  "printed",
  "dark",
  "equal",
  "hire",
  "drag",
  "minor",
  "raw",
  "soft",
  "solid",
  "weird",
  "annual",
  "count",
  "dead",
  "long",
  "double",
  "adult",
  "brief",
  "crazy",
  "escape",
  "prior",
  "rough",
  "sad",
  "sick",
  "strike",
  "mobile",
  "nasty",
  "royal",
  "senior",
  "yellow",
  "upper",
  "crash",
  "funny",
  "sweet",
  "spare",
  "usual",
  "laying",
  "brave",
  "calm",
  "grand",
  "male",
  "quiet",
  "prompted",
  "dear",
  "drunk",
  "female",
  "neat",
  "silly",
  "local",
  "stupid",
  "round",
];
