export const shortAdjectives = [
  "wet",
  "fat",
  "key",
  "top",
  "fun",
  "bad",
  "red",
  "sea",
  "one",
  "few",
  "set",
  "big",
  "low",
  "due",
  "raw",
  "sad",
  "size",
  "name",
  "kind",
  "head",
  "self",
  "shot",
  "baby",
  "pack",
  "gear",
  "blue",
  "team",
  "gold",
  "east",
  "west",
  "peak",
  "jury",
  "many",
  "most",
  "good",
  "high",
  "past",
  "main",
  "cold",
  "glad",
  "safe",
  "rich",
  "deep",
  "jump",
  "vast",
  "dark",
  "hire",
  "drag",
  "soft",
  "dead",
  "long",
  "sick",
  "calm",
  "male",
  "dear",
  "neat",
];
