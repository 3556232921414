export const gerunds = [
  "swimming",
  "being",
  "looking",
  "hurting",
  "anticipating",
  "avoiding",
  "completing",
  "considering",
  "delaying",
  "denying",
  "disgusting",
  "resisting",
  "gaming",
  "offering",
  "pretending",
  "tending",
  "homing",
  "sizing",
  "lining",
  "caring",
  "fated",
  "airing",
  "paging",
  "oiling",
  "naming",
  "rating",
  "sitting",
  "casing",
  "aging",
  "taxing",
  "acting",
  "ruling",
  "eyeing",
  "firing",
  "boxing",
  "facing",
  "timing",
  "hoping",
  "icing",
  "dating",
  "egging",
  "filing",
  "edging",
  "fanning",
  "waving",
  "bluing",
  "doting",
  "piping",
  "toning",
  "boning",
  "lying",
  "nosing",
  "caking",
  "coding",
  "easing",
  "gaping",
  "racing",
  "arming",
  "pining",
  "baking",
  "baring",
  "biking",
  "pacing",
  "rowing",
  "biting",
  "tuning",
  "biding",
  "cowing",
  "guying",
  "basing",
  "mating",
  "roping",
  "caning",
  "using",
  "making",
  "going",
  "giving",
  "trying",
  "moving",
  "paying",
  "asking",
  "buying",
  "lowing",
  "rising",
  "mixing",
  "flying",
  "saving",
  "riding",
  "crying",
  "eating",
  "fixing",
  "hiring",
  "taping",
  "whining",
  "tying",
  "hating",
  "laying",
  "hiding",
  "mining",
  "daring",
  "waking",
  "joking",
  "ending",
  "typing",
  "refusing",
  "learning",
  "trending",
  "waiting",
  "flattering",
  "jabbering",
  "faking",
  "practicing",
  "suggesting",
  "disliking",
  "enjoying",
  "imagining",
  "celebrating",
  "admitting",
];
